<template>
  <div class="relative grid cols-4 gap-y-3" style="column-gap: 0.125rem">
    <base-toggle
      v-for="({ id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      data-test-id="vf-size-filter"
    >
      <span
        class="h-full flex center b text-center duration peer-focus-visible:outline-auto"
        :class="model?.includes?.(id) ? 'b-black' : 'b-grey-60'"
        style="min-height: 1.75rem"
      >
        <span class="w-full select-none" data-test-id="vf-size-picker-text">
          {{ label }}
        </span>
      </span>
    </base-toggle>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { SizeSmallFilter } from '#types/filters'

defineProps<{
  filter: SizeSmallFilter
  loading?: boolean
}>()

const model = defineModel<string[]>()
</script>
