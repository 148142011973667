<template>
  <div class="relative" data-test-id="product-card">
    <base-picture
      :src="
        getImageTransformations(product.gallery?.[0]?.src, { ...mediaSize, align: 'north_east' })
          || defaultImageUrl"
      :alt="product.gallery?.[0]?.alt || product.name"
      :width="mediaSize.width"
      :height="mediaSize.height"
      :lazy
      class="w-full"
      data-test-id="product-card-pic"
    />
    <div class="mt-2 space-y-2 <md:px-2">
      <!--   eyebrow   -->
      <p
        v-if="product.eyebrow?.label"
        v-bind="eyebrowStyle"
        class="truncate eyebrow"
        data-test-id="product-card-eyebrow"
      >
        {{ product.eyebrow.label }}
      </p>
      <!--   title   -->
      <base-link
        :to="product.url"
        class="line-clamp-3"
        data-test-id="product-card-title"
        @click.prevent.capture="navigateToPDP"
      >
        <span class="absolute-0" />
        {{ product.name }}
      </base-link>

      <!--   price   -->
      <product-pricing
        :price="product.price"
        :currency="product.currency"
        :show-discount-percentage="showDiscountPercentage"
        class="text-sm"
        data-test-id="product-card-pricing"
      />
      <!-- available color count -->
      <p v-if="colors?.length > 1" class="c-grey-30" data-test-id="product-card-colors">
        {{ colors.length }} {{ $pluralize($t.colors, colors.length) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Breadcrumb, ColorOption } from '#types/product'
import type { ProductClickAction } from '#types/gtm'
import type { Product, ProductCardExtended } from '#root/api/clients/product/data-contracts'

const { breadcrumbs, product, trackingCategory } = withDefaults(defineProps<{
  /**
   * Page breadcrumbs from the product list
   */
  breadcrumbs?: Breadcrumb[]
  /**
   * Controls lazy load property of the default product image
   */
  lazy?: boolean
  /**
   * Product data
   */
  product: Product | ProductCardExtended
  /**
   * Tracking category for the click and details analytics events
   */
  trackingCategory?: string
  /**
   * Sets displaying the discount amount as percentage
   */
  showDiscountPercentage?: boolean
}>(), {
  breadcrumbs: () => ([])
})

const { $gtm } = useNuxtApp()
const { card: { mediaSize }, defaultImageUrl } = useAppConfig().components.product
const route = useRoute()
const router = useRouter()

const eyebrowStyle = computed(() => {
  const colorHexCode = product?.eyebrow?.colorHexCode
  return colorHexCode && colorHexCode !== 'None' ? { style: `color: #${colorHexCode}` } : {}
})

const colors = product.attributes.find((attr) => attr.type === 'color')?.options as ColorOption[]
const categoryURL = breadcrumbs[breadcrumbs.length - 1]?.url

const handleGtmEvent = (payload, action: ProductClickAction) =>
  $gtm.push('product.onProductClick', payload, {
    action,
    breadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q?.toString()
  })

const navigateToPDP = () => {
  handleGtmEvent(product, 'Navigate To PDP')
  router.push({
    path: product.url,
    state: {
      breadcrumbs: serializeAnalyticsBreadcrumbs(breadcrumbs),
      category: trackingCategory,
      searchTerm: route.query.q,
      categoryURL
    }
  })
}
</script>
