<template>
  <div data-scroll-el="productList" />
  <div
    ref="productListHeaderRef"
    class="sticky z-3 flex transform b-b b-grey-80 bg-white py-5 container between duration"
    :style="{ top: `${header.height.navigation / 16}rem` }"
  >
    <h1>
      {{ list?.title }}
    </h1>
    <base-button @click="PanelFilters.open">
      {{ $t.filterAndSort }}
    </base-button>
  </div>
  <div
    class="flex items-start justify-end bg-white py-3 container"
    :class="{ 'sticky z-3': hasAppliedFilters }"
    :style="{ top: `${(header.height.navigation + productListHeaderHeight) / 16}rem` }"
  >
    <filter-display
      v-bind="{ filters, currency }"
      :heading="$t.filters"
      :max-collapsed-rows="{ sm: 1, md: 1, lg: 1 }"
      @remove="removeFilter"
      @clear="clearFilters"
    />
    <div aria-live="polite" :class="{ absolute: hasAppliedFilters }">
      <div v-if="productsLoading" class="h-4 skeleton" style="width: 5.25rem" />
      <p v-else-if="hasFilters || list?.products?.length" class="ws-nowrap c-grey-30">
        {{ total }} {{ $pluralize($t.products, total) }}
      </p>
    </div>
  </div>

  <panel-filters v-slot="attrs" hide-close-button>
    <dialog-filters
      v-bind="attrs"
      :heading="$t.filterAndSort"
      :currency
      :total
      :loading="productsLoading || nextPageLoading"
      :filters
      :sort-state="sortState as string"
      :sorts="sorts[region]"
      @remove-filter="removeFilter"
      @clear-filters="clearFilters"
      @sort-change="onSortChange"
      @filter-item-change="onFilterItemChange"
    />
  </panel-filters>
</template>

<script lang="ts" setup>
import type { Catalog, Search } from '#root/api/clients/product/data-contracts'

const props = defineProps<{
  list: Catalog | Search | null
  productsLoading: boolean
  nextPageLoading: boolean
}>()

const PanelFilters = createPanel('filters')
const { sorts } = useAppConfig().pages.plp
const { $t } = useNuxtApp()
const region = useRegion()
const productListHeaderRef = ref()
const header = useHeaderStore()
const { height: productListHeaderHeight } = useElementBounding(productListHeaderRef)

const { clearFilters, filterBy, filters, filtersQuery, removeFilter, sortBy, sortState, } = useFilters(
  () => props.list?.filters || [],
  () => scrollToElement('productList')
)

const total = computed(() => props.list?.total || 0)
const currency = computed(() => props.list?.currency || 'USD')
const hasFilters = computed(() => !!Object.keys(filters.value)?.length)
const hasAppliedFilters = computed(() => !!Object.keys(filtersQuery.value).length)

const onSortChange = (sort: string) => {
  sortState.value = sort
  sortBy()
}

const onFilterItemChange = ({ code, selected }: { code: string, selected: string[] }) => {
  filters.value[code].selected = selected
  filterBy(code)
}
</script>
